<template>
  <div id="template_form">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-card no-body class="pt-0 mt-0">
            <b-card-body class="invoice-padding pb-0">
              <div class="d-flex mt-1">
                <h4 class="mb-0">Contract Template</h4>
              </div>
              <b-row>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
              <!-- Field: Template Name -->
              <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="Template Name"
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="Template Name"
                    label-for="template_name"
                    label-cols-md="3"
                  >
                    <b-form-input
                      id="template_name"
                      :state="getValidationState(validationContext)"
                      v-model="templateData.template_name"
                      placeholder="模版名稱"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Field: Template Remark -->
              <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="Template Remark"
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="Template Remark"
                    label-for="template_remark"
                    label-cols-md="3"
                  >
                    <b-form-input
                      id="template_remark"
                      :state="getValidationState(validationContext)"
                      v-model="templateData.template_remark"
                      placeholder="模版備註"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Field: Contract Title -->
              <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="Contract Title"
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="Contract Title"
                    label-for="template_main_title"
                    label-cols-md="3"
                  >
                    <b-form-input
                      id="template_main_title"
                      :state="getValidationState(validationContext)"
                      v-model="templateData.template_main_title"
                      placeholder="合約主標題(顥示於合約中的標題)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>  
               <!-- Field: Template Sign Position -->
              <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="Template Sign Position"
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="Template Sign Position"
                    label-for="template_sign_position"
                    label-cols-md="3"
                  >
                    <b-form-input
                      id="template_sign_position"
                      :state="getValidationState(validationContext)"
                      v-model="templateData.template_sign_position"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>  
              <!-- Field: Languages -->
              <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="Language"
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="Language"
                    label-for="contract_template_language_id"
                    label-cols-md="3"
                  >
                    <b-form-input
                      id="contract_template_language_id"
                      :state="getValidationState(validationContext)"
                      v-model="templateData.contract_template_language_id"
                      style="display:none;"
                    />
                    <v-select v-model="templateData.contract_template_language_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="languageOptions" label="language_name" :reduce="(option) => option.id" :clearable="false">
                           
                    </v-select>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col> 
              

             
            </b-card-body>
            <b-card-body class="invoice-padding form-item-section">
              <hr class="mt-1" />
              <div class="d-flex mt-1">
                <h5 class="mb-2">Templateing Detail</h5>
              </div>
              <div
                ref="form"
                class="invoice-items repeater-form"
                :style="{ height: trHeight }"
              >
                <b-row
                  v-for="(item, index) in templateData.contents"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="3">
                          <b-col cols="12" lg="12">
                            <validation-provider
                              #default="validationContext"
                              name="排序號碼"
                              rules="required"
                            >
                              <b-form-group
                                class="required"
                                label="排序號碼"
                                label-for="content_sort_id"
                              >
                                <b-form-input
                                  id="content_sort_id"
                                  :state="getValidationState(validationContext)"
                                  v-model="item.content_sort_id"
                          
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-col>
                        <b-col cols="12" lg="3">
                          <b-col cols="12" lg="12">
                            <validation-provider
                              #default="validationContext"
                              name="標題"
                              rules="required"
                            >
                              <b-form-group
                                class="required"
                                label="標題"
                                label-for="content_title"
                              >
                                <b-form-input
                                  id="content_title"
                                  :state="getValidationState(validationContext)"
                                  v-model="item.content_title"
                          
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-col>
                        <b-col cols="12" lg="6">
                          <validation-provider
                            #default="validationContext"
                            name="Content"
                            rules="required"
                          >
                            <b-form-group
                              class="content_content required"
                              label="內容"
                              label-for="content_content"
                            >
                              <b-form-input
                                id="content_content"
                                :state="getValidationState(validationContext)"
                                v-model="item.content_content"
                                style="display: none"
                              />
                              <ckeditor v-model="item.content_content" />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <div
                        class="
                          d-flex
                          flex-column
                          justify-content-between
                          border-left
                          py-50
                          px-25
                        "
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
                :style="{ height: trHeight }"
              ></div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm"
              >
                Add Detail
              </b-button>
            </b-card-body>
            <hr />
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ button_text }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-card>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BInputGroupAppend,
  BInputGroup,
  BFormInvalidFeedback,
  BOverlay,
  BCard,
  BCardBody,
} from "bootstrap-vue";
import { ref, onMounted, watch } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import "animate.css";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { heightTransition } from "@core/mixins/ui/transition";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  data() {
      return {
          editorOption:{
              /*
                    modules:{
                        toolbar:[
                          ['background','bold','color','font','code', 'italic','link','size','script', 'underline', 'strike'],        // toggled buttons
                           ['blockquote','header','ident','list','align', 'direction', 'code-block'],
                           ['formula','image','video']
                        ]
                    }
                */
                }
      }
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BInputGroupAppend,
    BInputGroup,
    BFormInvalidFeedback,
    BOverlay,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  methods: {
    onSubmit() {
      this.$swal({
        title: "確認儲存?",
        showCancelButton: true,
        confirmButtonText: "儲存",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("contract_template/addTemplate", this.templateData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              if (this.templateData.id == 0) {
                // Return to client list
                this.hide();
               //	sp1.hk.cmbintl.com
               this.templateData.id = response.data.id
               //this.redirect(response.data.id)
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    hide() {
      this.$router.replace("/settings/contract/template/list");
    },
    redirect(id){
      this.$router.replace("/templates/edit/"+id)
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      })
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.templateData.contents.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );

      // this.$nextTick(() => {
      //     this.trAddHeight(this.$refs.row[0].offsetHeight)
      //     setTimeout(() => {
      //         this.$refs.form.style.overflow = null
      //     }, 350)
      // })
    },
    removeItem(index) {
      if(this.templateData.contents.length > 1)
      {
          this.templateData.contents.splice(index, 1);
      }else{
           this.makeToast('danger', 'Warning','Please submit at least one templateing detail.')
      }
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
  props: {
    templateData: {
      type: Object,
      required: true,
    },
    languageOptions:{
        type:Array,
        required:true,
    }
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const button_text = ref("Save");
    const resettemplateData = () => {
      props.templateData = JSON.parse(JSON.stringify({}));
    };

    const itemFormBlankItem = {
      content_sort_id:0,
      content_title:'',
      content_content:'',
    };



    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resettemplateData);

    return {
      button_text,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      itemFormBlankItem,
   
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.v-select {
    &.item-selector-title,
    &.payment-selector {
        background-color: #fff;
        .dark-layout & {
            background-color: unset;
        }
    }
}
</style>