<template>
    <component :is="templateData === undefined ? 'div' : 'b-card'">
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="templateData === undefined">
            <h4 class="alert-heading">Error fetching template data</h4>
            <div class="alert-body">
                No template found with this template id. Check
                <b-link class="alert-link" :to="{ name: 'templates-list' }">
                    Template List
                </b-link>
                for other templates.
            </div>
        </b-alert>
        <template-edit-tab-information v-if="templateData" :template-data="templateData" :language-options="languageOptions"  @refetch-data="refetchData" class="mt-2 pt-75" />
    </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import templateStoreModule from "@/views/setting/contract/template/templateStoreModule";
// import TemplateEditTabAccount from "./TemplateEditTabAccount.vue";
import TemplateEditTabInformation from "./TemplateEditTabInformation.vue";


export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        // TemplateEditTabAccount,
        TemplateEditTabInformation,
    },
    setup() {
        const templateData = ref(null)
        const languageOptions = ref([])
        const SETTING_TEMPLATE_STORE_MODULE_NAME = "contract_template"

        const template = {
            id: 0,
            template_name:'',
            template_remark: '',
            template_main_title: '',
            template_sign_position: 100,
            contents: [{
                content_sort_id:0,
                content_title:'',
                content_content:'',
            }]
        }
        // Register module
        if (!store.hasModule(SETTING_TEMPLATE_STORE_MODULE_NAME))
            store.registerModule(SETTING_TEMPLATE_STORE_MODULE_NAME, templateStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(SETTING_TEMPLATE_STORE_MODULE_NAME))
                store.unregisterModule(SETTING_TEMPLATE_STORE_MODULE_NAME);
        });

        const refetchData = () => {
            if (router.currentRoute.name == 'templates-create') {
                templateData.value = template
            } else {
                store
                    .dispatch(SETTING_TEMPLATE_STORE_MODULE_NAME+"/fetchTemplate", { id: router.currentRoute.params.id })
                    .then((response) => {
                        templateData.value = response.data.contract_template;
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            templateData.value = undefined;
                        }
                    });
            }

        };

        store.dispatch(SETTING_TEMPLATE_STORE_MODULE_NAME+'/fetchLanguages')
        .then(response => {
            languageOptions.value = response.data.languages
        })
        .catch(error => {
            languageOptions.value = undefined
        })

        refetchData()

        return {
            templateData,
            refetchData,
            languageOptions,
        };
    },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

    #template_form .ql-editor {
    height: 150px;
    }



    #template_form .date_from > label::before {
        content: "From";
        position:absolute;
        right:5%;
    }

    #template_form .date_to > label::before {
        content: "To";
        position:absolute;
        right:5%;
    }

</style>
